import React, { useEffect } from "react";
import styled from "styled-components";
import { historyList } from "./infoDate";
// import { Parallax, ParallaxLayer } from "@react-spring/parallax";

const Information = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Style>
      <div className="wrapper">
        <div className="header">
          <img
            src={`${process.env.PUBLIC_URL}/img/info_header.png`}
            alt="news_header"
          />
          <h1>회사 소개</h1>
        </div>
        <div className="content">
          <div className="subtitle">
            <h1>INVISIBLE IDEAS</h1>
            <h2>
              {
                "인비저블 아이디어는 \n일상생활에서 반복되는 사소한 불편함을 \n새로운 아이디어로 해결하고자 합니다."
              }
            </h2>
            <h2>
              {
                "작은 변화를 통해 세상의 큰 변화를 이끄는 \n서비스를 만들기 위해 항상 노력하겠습니다."
              }
            </h2>
            <h2>
              {
                "에너지와 비용을 아껴 가치를 만들고 \n그 가치로 소비자와 판매자 모두에게 이로운 \n서비스를 만들겠습니다."
              }
            </h2>
          </div>
          <div className="subtitle">
            <h1>연혁</h1>
            <div>
              {historyList.map((item, index) => (
                <div className="history" key={index}>
                  <h1 className="year" style={{ color: item.date_color }}>
                    {item.date}
                  </h1>
                  <div className="historyBar">
                    <div className="ball desktop" />
                    <div
                      className="ball mobile"
                      style={{ borderColor: item.date_color }}
                    />
                    {index !== historyList.length - 1 && (
                      <div className="bar" />
                    )}
                  </div>
                  <div className="historyItem desktop">
                    {item.historys.map((history, historyIdx) => (
                      <h3
                        key={historyIdx}
                        style={{ fontWeight: history.bold ? "700" : "500" }}
                      >
                        <span>{String(history.month).padStart(2, "0")}.</span>
                        {history.title}
                      </h3>
                    ))}
                  </div>
                  <div className="historyItem mobile">
                    {item.mobile_historys.map((history, historyIdx) => (
                      <h3
                        key={historyIdx}
                        style={{ fontWeight: history.bold ? "700" : "500" }}
                      >
                        <span>{String(history.month).padStart(2, "0")}.</span>
                        {history.title}
                      </h3>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default Information;

const Style = styled.div`
  /* 공통 */
  .desktop {
    display: inline;
  }
  .mobile {
    display: none;
  }

  .header {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      filter: brightness(0.5);
    }
    h1 {
      position: absolute;
      left: 16%;
      top: 50%;
      transform: translateY(-50%);
      font-size: 80px;
      line-height: 110px;
      color: #fff;
      font-weight: 700;
    }
  }
  .content {
    padding: 0 16%;

    .subtitle {
      padding: 10% 0 14% 0;
      h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 70px;
        margin-bottom: 40px;
        color: #333333;
      }
      h2 {
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
        color: #979797;
      }
    }
  }

  .history {
    display: flex;
    gap: 30px;
    h1.year {
      width: 210px;
      font-size: 70px;
      line-height: 54px;
    }
    .historyBar {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 6px 0;
      gap: 10px;
      .ball {
        border-radius: 50px;
        width: 12px;
        height: 12px;
        border: 4px solid #c7c7c7;
      }
      .bar {
        flex: 1;
        width: 1px;
        background: #c7c7c7;
      }
    }
    .historyItem {
      text-align: left;
      margin-top: -12px;
      padding-bottom: 70px;
      h3 {
        font-size: 18px;
        line-height: 48px;
        font-weight: 500;
        color: #555;
      }
      span {
        font-size: 18px;
        line-height: 48px;
        font-weight: 500;
        color: #979797;
        padding-right: 10px;
      }
    }
  }

  @media (max-width: 800px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline;
    }

    .desktop {
      display: none;
    }
    .mobile {
      display: inline;
    }
    .header {
      img {
        height: 240px;
        object-fit: cover;
        filter: brightness(0.5);
      }
      h1 {
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        line-height: 48px;
        text-align: center;
      }
    }
    .content {
      padding: 6%;

      .subtitle {
        text-align: center;
        h1 {
          font-size: 28px;
          line-height: 60px;
          margin-bottom: 30px;
        }
        h2 {
          font-size: 16px;
          line-height: 24px;
          white-space: pre-line;
          margin-bottom: 20px;
        }
      }
    }

    .history {
      gap: 10px;
      h1.year {
        min-width: 50px;
        max-width: 50px;
        font-size: 18px;
        line-height: 28px;
      }
      .historyBar {
        gap: 10px;
      }
      .historyItem {
        margin-top: -4px;
        padding-bottom: 60px;
        h3 {
          font-size: 14px;
          line-height: 32px;
          font-weight: 500;
          color: #555;
        }
        span {
          font-size: 14px;
          line-height: 32px;
          font-weight: 500;
          color: #979797;
          padding-right: 10px;
        }
      }
    }
  }
`;
