import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Parallax } from "react-parallax";
import ImageSlider from "../../components/ImageSlider";
import Contact from "../../components/Contact";
import { newsData } from "../news/NewsData";

type newsItemType = {
  src: string;
  url: string;
  title: string;
  company: string;
  published_time: string;
  type: number;
};
const MainHomePage = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);
  const [isVisible, setIsVisible] = useState<boolean[]>([false]);
  const bannerImages = isMobile
    ? [
        {
          src: "https://web-image-file.s3.ap-northeast-2.amazonaws.com/ivid/ividBanner01.png",
          className: "bannerImage01",
          bannerType: "ivid",
        },
        {
          src: "https://web-image-file.s3.ap-northeast-2.amazonaws.com/ivid/ividBannerMob02.png",
          className: "bannerImage02",
          bannerType: "throo",
        },
        {
          src: "https://web-image-file.s3.ap-northeast-2.amazonaws.com/ivid/ividBannerMob03.png",
          className: "bannerImage03",
          bannerType: "behind",
        },
        {
          src: "https://web-image-file.s3.ap-northeast-2.amazonaws.com/ivid/ividBannerMob04.png",
          className: "bannerImage04",
          bannerType: "knockknock",
        },
      ]
    : [
        {
          src: "https://web-image-file.s3.ap-northeast-2.amazonaws.com/ivid/ividBanner01.png",
          className: "bannerImage01",
          bannerType: "ivid",
        },
        {
          src: "https://web-image-file.s3.ap-northeast-2.amazonaws.com/ivid/ividBanner02.jpg",
          className: "bannerImage02",
          bannerType: "throo",
        },
        {
          src: "https://web-image-file.s3.ap-northeast-2.amazonaws.com/ivid/ividBanner03.jpg",
          className: "bannerImage03",
          bannerType: "behind",
        },
        {
          src: "https://web-image-file.s3.ap-northeast-2.amazonaws.com/ivid/ividBanner04.jpg",
          className: "bannerImage04",
          bannerType: "knockknock",
        },
      ];

  // 링크이동
  const throoHomepage = () => {
    window.open("https://throo.co.kr/", "_blank");
  };
  const behindHomepage = () => {
    window.open("https://behind.ivid.kr/", "_blank");
  };
  const knockHomepage = () => {
    window.open("https://knock-knock.ivid.kr/", "_blank");
  };

  // text 애니메이션
  const hideRefs = [
    useRef<HTMLHeadingElement>(null),
    useRef<HTMLHeadingElement>(null),
    useRef<HTMLHeadingElement>(null),
    useRef<HTMLHeadingElement>(null),
    useRef<HTMLHeadingElement>(null),
    useRef<HTMLHeadingElement>(null),
  ];
  const newsBoxWrapRef = useRef<any>(null);
  const handleRowScroll = (direction: any) => {
    if (newsBoxWrapRef.current) {
      if (direction === "left") {
        newsBoxWrapRef.current.scrollLeft -= 450;
      } else {
        newsBoxWrapRef.current.scrollLeft += 450;
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.scrollHeight;

      hideRefs.forEach((ref, index) => {
        const element = ref.current;
        if (element) {
          const elementTop = element.offsetTop;
          const elementHeight = element.offsetHeight;
          const isElementVisible =
            scrollTop + windowHeight > elementTop &&
            scrollTop < elementTop + elementHeight;
          setIsVisible((prev) => {
            const newVisible = [...prev];
            newVisible[index] = isElementVisible;
            return newVisible;
          });
        }
      });

      if (scrollTop + windowHeight === documentHeight) {
        setIsVisible((prev) => {
          const newVisible = [...prev];
          newVisible[newVisible.length - 1] = true;
          return newVisible;
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Style>
      <div className="wrapper">
        <div className="banner">
          <ImageSlider images={bannerImages} />

          <div className="scrollDownWrap webView">
            <h2>Scroll Down</h2>
            <div className="circle"></div>
          </div>
        </div>
        <div className="content black">
          <h2>
            인비저블 아이디어는 외식산업 혁신을 위한
            <br />
            <span
              ref={hideRefs[0]}
              className={` ${isVisible[0] ? "in_view fade_in" : "fade_in"}`}
            >
              이종 융합된 솔루션
            </span>
            을 개발하고 있습니다.
          </h2>
        </div>
        <Parallax
          strength={1000}
          renderLayer={(percentage) => {
            const scale = Math.min(1, 0.49 + percentage * 0.49);
            return (
              <div
                className="parallaxWrapper"
                style={{
                  transform: `translate(-50%, -50%) scale(${scale})`,
                  backgroundImage: `url(https://web-image-file.s3.ap-northeast-2.amazonaws.com/ivid/ividParallaxBackground.jpg)`,
                }}
              >
                <div className="parallaxWrap">
                  <h1>
                    INNOVATIVE
                    <br />
                    SOLUTIONS
                  </h1>
                  <p>
                    매장 운영에 필요한 모든것, 인비저블아이디어 솔루션으로
                    해결하세요
                  </p>
                </div>
              </div>
            );
          }}
        >
          <div
            ref={hideRefs[1]}
            className={`businessTypeWrap throo ${
              isVisible[1] ? "fade_long_up" : "in_view fade_long_up"
            }`}
          >
            <img
              className="appImage"
              src={`${process.env.PUBLIC_URL}/img/throo.png`}
              alt="스루앱"
            />
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/img/throoLogo.png`}
                alt="스루로고"
                width={"166px"}
              />
              <p>
                운전자를 위한 스마트한 주문!
                <br />
                언제 어디서나 스루를 만나보세요
              </p>
              <button onClick={throoHomepage} className="pathMoveButton">
                <h4>바로가기</h4>
                <img
                  src={`${process.env.PUBLIC_URL}/svg/ic_path.svg`}
                  alt="바로가기 아이콘"
                />
              </button>
            </div>
          </div>
          <div
            ref={hideRefs[2]}
            className={`businessTypeWrap behind reverse ${
              isVisible[2] ? "fade_long_up" : "in_view fade_long_up"
            }`}
          >
            <img
              className="appImage"
              src={`${process.env.PUBLIC_URL}/img/behind.png`}
              alt="비하인드 키오스크"
            />
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/img/behindLogo.png`}
                alt="비하인드로고"
                width={"166px"}
              />
              <p>
                사장님의 성공 파트너,
                <br />
                비하인드와 더 스마트하게 운영하세요!
              </p>
              <button onClick={behindHomepage} className="pathMoveButton">
                <h4>바로가기</h4>
                <img
                  src={`${process.env.PUBLIC_URL}/svg/ic_path.svg`}
                  alt="바로가기 아이콘"
                />
              </button>
            </div>
          </div>
          <div
            ref={hideRefs[3]}
            className={`businessTypeWrap knock ${
              isVisible[3] ? "fade_long_up" : "in_view fade_long_up"
            }`}
          >
            <img
              className="appImage"
              src={`${process.env.PUBLIC_URL}/img/knockknock.png`}
              alt="노크노크앱"
            />
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/img/knockknockLogo.png`}
                alt="노크노크로고"
                width={"166px"}
              />
              <p>
                새로운 개념의 룸서비스!
                <br />
                호텔 객실 스마트오더 솔루션
              </p>
              <button onClick={knockHomepage} className="pathMoveButton">
                <h4>바로가기</h4>
                <img
                  src={`${process.env.PUBLIC_URL}/svg/ic_path.svg`}
                  alt="바로가기 아이콘"
                />
              </button>
            </div>
          </div>
          <div className="parallaxBackground" />
        </Parallax>

        <div className="subcontent">
          <div className="title">
            <h2>
              인비저블 아이디어의
              <br />
              새로운 소식을 소개합니다
            </h2>
            <div className="scrollButtonWrap">
              <button onClick={() => handleRowScroll("left")}>
                <img
                  src={`${process.env.PUBLIC_URL}/svg/ic_left.svg`}
                  alt="왼쪽 아이콘"
                />
              </button>
              <button onClick={() => handleRowScroll("right")}>
                <img
                  src={`${process.env.PUBLIC_URL}/svg/ic_right.svg`}
                  alt="왼쪽 아이콘"
                />
              </button>
            </div>
          </div>

          <div ref={newsBoxWrapRef} className="newsBoxWrap">
            {newsData.map((data) => (
              <div
                onClick={() => window.open(data.url, "_blank")}
                className="newsBox"
              >
                <img className="newsImage" src={data.src} alt="뉴스 이미지" />
                <h4>보도자료</h4>
                <h3>{data.title}</h3>
                <p>{data.published_time}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="subcontent">
          <div className="title">
            <h3>
              고객사에서 선택한 전문기업!
              <br />
              <strong>
                인비저블아이디어의 솔루션과 서비스를 이용하고 있습니다.
              </strong>
            </h3>
          </div>
          <div className="imageSliderWrap">
            <div className="imageSliderTrack">
              <div className="imageSliderTop">
                <img
                  src={`${process.env.PUBLIC_URL}/img/partnersLogo01.png`}
                  alt="지도 아이콘"
                />
              </div>
              <div className="imageSliderTop">
                <img
                  src={`${process.env.PUBLIC_URL}/img/partnersLogo01.png`}
                  alt="지도 아이콘"
                />
              </div>
            </div>
            <div className="imageSliderTrack">
              <div className="imageSliderBottom">
                <img
                  src={`${process.env.PUBLIC_URL}/img/partnersLogo02.png`}
                  alt="지도 아이콘"
                />
              </div>
              <div className="imageSliderBottom">
                <img
                  src={`${process.env.PUBLIC_URL}/img/partnersLogo02.png`}
                  alt="지도 아이콘"
                />
              </div>
            </div>
          </div>
        </div>
        <Contact />
        <div className="footer"></div>
      </div>
    </Style>
  );
};

export default MainHomePage;

const Style = styled.div`
  /* 공통 */
  .webView {
    display: block;
  }
  .mobileView {
    display: none !important;
  }
  .in_view {
    opacity: 1;
    transition: all 1s ease-in-out;
  }
  .fade_in {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
  .fade_up {
    opacity: 0;
    transform: translate3d(0, 15%, 0);
  }
  .fade_long_up {
    opacity: 1;
    transform: translate3d(0, 30%, 0);
  }
  .in_view.fade_in,
  .in_view.fade_up {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .in_view.fade_long_up {
    opacity: 1;
    transform: translate3d(0, -5%, 0);
  }
  h2 {
    font-size: 44px;
    font-weight: 700;
    color: #fff;
    line-height: 64px;

    span {
      background: linear-gradient(90deg, #4dd2ae 20%, #5096ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
    }
  }

  /* wrapper */
  .wrapper {
    width: 100%;
    max-width: 100%;

    img {
      vertical-align: top;
      margin-top: -1px;
    }
  }

  /* banner */
  .slide {
    position: relative;
  }
  .titleWrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 20%;
    left: 12%;

    h2 {
      font-size: 100px;
      font-weight: 700;
      line-height: 160px;
      color: #fff;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: #fff;
      line-height: 32px;
      white-space: pre;
    }
  }
  .buttonWrap {
    position: absolute;
    bottom: 35%;
    right: 25%;
    height: auto;

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 40px;
      border: 2px solid rgba(255, 255, 255, 0.4);
      border-radius: 100px;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      cursor: pointer;

      img {
        width: 36px;
        margin: 0;
        margin-left: 14px;
      }
    }
  }
  /* scrollDownWrap */
  .scrollDownWrap {
    position: absolute;
    bottom: 3%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin-bottom: 50px;
      font-size: 16px;
      font-weight: 600;
      color: #979797;
      writing-mode: vertical-rl;
    }
  }
  .circle {
    position: absolute;
    bottom: 0;
    width: 6px;
    height: 6px;
    margin-left: 4px;
    transform: translateX(-50%);
    background-color: #979797;
    border-radius: 50%;
    animation: circleMotion 0.65s infinite alternate
      cubic-bezier(0.1, 0.49, 0.42, 0.99);
  }
  @keyframes circleMotion {
    0% {
      bottom: 0;
    }
    100% {
      bottom: 30px;
    }
  }

  /* content */
  .content {
    width: 100%;
    max-width: 100%;
    padding: 330px 0;
    background: #fff;
  }
  .content.black {
    background: #1d1d1d;
    text-align: center;
  }

  /* subcontent */
  .subcontent {
    padding: 160px 300px;

    .title {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      h2 {
        font-size: 48px;
        font-weight: 700;
        color: #333;
        line-height: 67px;
      }
      h3 {
        font-size: 40px;
        font-weight: 500;
        color: #333;
        strong {
          line-height: 1.7;
        }
      }
    }
    .scrollButtonWrap {
      display: flex;
      gap: 50px;
      button {
        background: none;
        outline: none;
        border: none;
      }
    }
  }
  .businessTypeWrap {
    display: flex;
    align-items: center;
    gap: 20%;

    p {
      width: 100%;
      margin: 50px 0 60px 0;
      font-size: 24px;
      font-weight: 33px;
      color: #979797;
      line-height: 33px;
      white-space: nowrap;
    }
    .pathMoveButton {
      display: flex;
      align-items: center;
      padding: 8px 30px;
      background: #00000033;
      border: 1px solid #979797;
      border-radius: 50px;
      gap: 20px;

      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #c7c7c7;
      }
    }
  }
  .businessTypeWrap.throo {
    position: absolute;
    top: 28%;
    left: 15%;
    img {
      max-width: 357px;
    }
  }
  .businessTypeWrap.behind {
    position: absolute;
    top: 50%;
    right: 15%;
    img {
      max-width: 320px;
    }
  }
  .businessTypeWrap.knock {
    position: absolute;
    bottom: 5%;
    left: 15%;

    img {
      max-width: 312px;
    }
  }
  .businessTypeWrap.reverse {
    flex-direction: row-reverse;
  }
  .newsBoxWrap {
    margin-top: 60px;
    display: flex;
    gap: 30px;
    overflow: hidden;
    scroll-behavior: smooth;

    img {
      width: 100%;
      width: 427px;
      height: 320px;
      border-radius: 20px;
    }
  }
  .newsBox {
    min-width: 420px;
    cursor: pointer;

    h4 {
      margin-top: 33px;
      font-size: 20px;
      font-weight: 600;
      color: #6a6a6a;
    }
    h3 {
      width: 90%;
      margin: 10px 0;
      line-height: 33px;
      font-size: 24px;
      font-weight: 600;
      word-break: keep-all;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: #a9a9a9;
    }
  }
  /* parallax */
  .content.parallax {
    padding: 0;
  }
  .parallaxWrapper {
    width: 100%;
    height: 3000px;
    min-height: 3000px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-size: cover;
    background-position: center;
  }
  .parallax {
    height: 41000px;
    background: #fff;
  }
  .parallaxBackground {
    height: 3000px;
  }
  .parallaxWrap {
    position: relative;
    top: 10%;
    left: 15%;

    h1 {
      font-size: 100px;
      font-weight: 700;
      color: #fff;
      line-height: 120px;
    }
    p {
      font-size: 28px;
      font-weight: 400;
      color: #fff;
      margin-top: 29px;
    }
  }

  /* imageSliderWrap */
  .imageSliderWrap {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .imageSliderTrack {
    width: 200%;
    display: flex;
    margin-top: 20px;
    gap: 4%;
  }

  .imageSliderTop,
  .imageSliderBottom {
    width: 50%;
    white-space: nowrap;
    margin: 20px 0px;
  }

  .imageSliderTop {
    animation: scrollRight 24s linear infinite;
    height: auto;
  }

  .imageSliderBottom {
    animation: scrollLeft 24s linear infinite;
    height: auto;
  }

  @keyframes scrollRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes scrollLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @media (max-width: 1700px) {
  }
  /* @media (max-width: 1400px) {
    .content {
      background:pink;
      padding: 205px 0 120px 0;
    }
    .flexButton.consulting {
      a,
      button {
        width: 100%;
      }
    }
  } */
  @media (max-width: 1400px) {
    .contactWrap {
      padding: 90px 8vw;
    }
    .subcontent {
      padding: 200px 8vw;
    }
    .buttonWrap {
      right: 16vw;
      bottom: 48%;

      button {
        padding: 10px 20px;
        font-size: 18px;

        img {
          width: 28px;
          margin: 0;
          margin-left: 10px;
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1100px) {
    .webView {
      display: none;
    }
    .titleWrap {
      width: 100%;

      h2 {
        margin-bottom: 10px;
        font-size: 60px;
        line-height: 80px;
      }
      p {
        width: 80%;
        font-size: 20px;
        line-height: 40px;
        white-space: normal;
        word-break: keep-all;
      }
    }
    .parallaxWrap {
      top: 7%;
      h1 {
        font-size: 80px;
        line-height: 100px;
      }
    }
    .businessTypeWrap {
      flex-direction: column !important;
      width: 100%;

      div {
        width: 100%;
        margin-top: 40px;
        text-align: center;
      }
      .pathMoveButton {
        margin: 0 auto;
      }
    }
    .businessTypeWrap.throo {
      top: 20%;
      left: 0;

      .appImage {
        max-width: 160px;
      }
    }
    .businessTypeWrap.behind {
      top: 45%;
      right: 0;
      .appImage {
        max-width: 150px;
      }
    }
    .businessTypeWrap.knock {
      bottom: 3%;
      left: 0;

      .appImage {
        max-width: 200px;
      }
    }
    .imageSliderTrack {
      margin-top: 10px;
      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 800px) {
    .fade_long_up {
      opacity: 1;
      transform: translate3d(0, 10%, 0);
    }
    .in_view.fade_long_up {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }

    h2 {
      font-size: 18px;
      line-height: 32px;
    }
    .titleWrap {
      left: 5%;
      h2 {
        font-size: 40px;
        line-height: 60px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        white-space: normal;
        word-break: keep-all;
      }
    }
    .content {
      padding: 120px 0;
    }
    .subcontent {
      padding: 45px 4vw;
      .title {
        h2,
        h3 {
          font-size: 20px;
          line-height: 26px;
        }
      }
      .scrollButtonWrap {
        display: none !important;
      }
    }
    .parallaxWrapper {
      height: 2500px;
      min-height: 2500px;
    }
    .parallaxBackground {
      height: 2500px;
    }
    .parallaxWrap {
      top: 5%;
      left: 0;
      text-align: center;

      h1 {
        font-size: 32px;
        line-height: 38px;
      }
      p {
        padding: 0 8vw;
        font-size: 18px;
        line-height: 24px;
        white-space: pre-line;
        word-break: keep-all;
      }
    }
    .newsBoxWrap {
      width: 100%;
      overflow-x: scroll;
      gap: 20px;

      img {
        width: 100%;
        height: 200px;
      }
    }
    .newsBox {
      min-width: 280px;

      h4 {
        margin-top: 16px;
        font-size: 14px;
      }
      h3 {
        line-height: 25px;
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }
    }

    .imageSliderTrack {
      margin: 0;
    }
    .businessTypeWrap {
      gap: 0%;

      p {
        margin: 26px 0;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .businessTypeWrap.throo {
      top: 15%;
    }
    .businessTypeWrap.behind {
      top: 40%;
    }
    .businessTypeWrap.knock {
      bottom: 3%;
    }
  }
`;
