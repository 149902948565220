import React from "react";
import { useOutlet } from "react-router-dom";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";

export const AllLayout = (oProps: any) => {
  const outlet = useOutlet();

  return (
    <Style>
      <div className="screen">
        <Header />
        {outlet}
        <Footer />
      </div>
    </Style>
  );
};

export const Style = styled.div`
  .screen {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
`;
