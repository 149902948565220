import Axios, { Response } from "../utils/Axios";

export type ContactusType = {
  inquiry_type: string;
  business_type: string;
  name: string;
  company: string;
  email: string;
  phonenumber: string;
  text: string;
};

const MainController = {
  contactus: async (data: ContactusType) => {
    try {
      let sUrl =
        process.env.REACT_APP_API_KNOCKKNOCK_URL + "/auth/ivid/contactus";
      const oParams = data;
      const response: any = await Axios.post(sUrl, oParams);
      return response;
    } catch (err) {
      let oErr: any = err;
      return {
        ok: false,
        msg: "[인비저블아이디어 홈페이지 도입 문의 오류]" + oErr.toString(),
        data: undefined,
      };
    }
  },
};

export default MainController;
