import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "./Sidebar";

const HeaderComponent = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdown = (button: string) => {
    setActiveButton(activeButton === button ? null : button);
  };

  // 링크이동
  const movePage = (type: string) => {
    toggleDropdown("");

    if (type === "throo") {
      window.open("https://throo.co.kr/", "_blank");
    } else if (type === "behind") {
      window.open("https://behind.ivid.kr/", "_blank");
    } else if (type === "knock") {
      window.open("https://knock-knock.ivid.kr/", "_blank");
    } else if (type === "info") {
      navigate("/info");
    } else if (type === "news") {
      navigate("/news");
    } else if (type === "contact") {
      navigate("/contact");
    }
  };
  const smartStorePage = () => {
    window.open("https://smartstore.naver.com/throo", "_blank");
  };

  return (
    <Style>
      <div className="headerWrap">
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/svg/logo_white.svg`}
            alt="인비저블아이디어 로고"
            className="logo"
          />
        </Link>
        <div className="movePathWrap">
          <div className="dropdownWrap">
            <button onClick={() => toggleDropdown("COMPANY")}>COMPANY</button>
            <div
              className={`dropdown ${activeButton === "COMPANY" ? "open" : ""}`}
            >
              <div className="subBtn">
                <p onClick={() => movePage("info")}>회사소개</p>
                <p onClick={() => movePage("news")}>뉴스</p>
              </div>
            </div>
          </div>
          <div className="dropdownWrap">
            <button onClick={() => toggleDropdown("SOLUTION")}>SOLUTION</button>
            <div
              className={`dropdown ${
                activeButton === "SOLUTION" ? "open long" : ""
              }`}
            >
              <div className="subBtn">
                <p onClick={() => movePage("throo")}>FOODTECH</p>
                <p onClick={() => movePage("behind")}>MOBILITY</p>
                <p onClick={() => movePage("knock")}>TRAVEL-TECH</p>
              </div>
            </div>
          </div>
          <button onClick={() => movePage("contact")}>CONTACT US</button>
        </div>
        <button className="hameburgerBar" onClick={toggle}>
          <img
            src={`${process.env.PUBLIC_URL}/svg/ic_hamburgerbar.svg`}
            alt="사이드바 열기 버튼"
          />
        </button>
      </div>
      {isOpen && <Sidebar toggle={toggle} />}
    </Style>
  );
};
export default HeaderComponent;

const Style = styled.div`
  .hameburgerBar {
    display: none !important;
  }

  /* headerWrap */
  .headerWrap {
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 2%;
    z-index: 999;
    gap: 130px;
    background: #000;
  }

  a {
    width: 200px;
    height: 20px;
    img {
      width: 200px;
      height: 100%;
    }
  }

  /* movePathWrap */
  .movePathWrap {
    width: auto;
    display: flex;
    gap: 71px;

    button {
      padding: 11px 32px;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      background: none;
      border: none;
    }
  }

  /* dropdown */
  .dropdownWrap {
    position: relative;
  }
  .dropdown {
    width: 160px;
    height: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -0%);
    top: 100%;
    overflow: hidden;
    transition: height 0.3s ease-out;
    background: #f9f9f9;
    padding: 0 16px;
    border-radius: 20px;
  }
  .dropdown.open {
    width: 160px;
    height: 100px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px 0px 20px;
  }
  .dropdown.open.long {
    height: 140px;
  }
  .subBtn p {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #555;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
  }

  @media (max-width: 1100px) {
    .movePathWrap {
      display: none;
    }
    .logo {
      width: 80%;
      display: block;
    }
    .headerWrap {
      padding: 10px 20px;
      background: none;
      justify-content: space-between;
      background: #000;
    }


    .hameburgerBar {
      display: block !important;
      background: none;
      border: none;

      img {
        width: 100%;
        max-width: 32px;
        height: 32px;
      }
    }
  }
`;
