import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AllLayout } from "./screens/AllLayout";
import MainHomePage from "./screens/homepage/main";
import CompanyInfo from "./screens/company/CompanyPage";
import "./index.css";
import NewsPage from "./screens/news/NewsPage";
import Information from "./screens/info/Information";
import Contact from "./components/Contact";

const App = () => {
  useEffect(() => {}, []);

  return (
    <Routes>
      <Route path="/" element={<AllLayout />}>
        <Route path="/" element={<MainHomePage />} />
        {/* <Route path="/" element={<CompanyInfo />} /> */}
        <Route path="/news" element={<NewsPage />} />
        <Route path="/info" element={<Information />} />
        <Route path="/info" element={<Information />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
    </Routes>
  );
};

export default App;
