import { toastState } from "../recoil/toast";
import { useRecoilState } from "recoil";

interface ToastFunction {
  (message: string, type?: string, second?: number): void;
}

export const useToast = (): ToastFunction => {
  const [toast, setToast] = useRecoilState(toastState);

  const showToast: ToastFunction = (message, type, second) => {
    setToast({ message, isVisible: true, type: type || "success" });

    setTimeout(() => {
      setToast({ message: "", isVisible: false, type: "success" });
    }, second || 800);
  };

  return showToast;
};
