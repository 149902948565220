import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface BannerType {
  bannerType: "ivid" | "throo" | "behind" | "knockknock" | string;
  borderColor: string;
  color: string;
  svgSrc?: string;
}

const ImageSlider = ({ images, type }: any) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(true);

  // 링크이동
  const smartStorePage = () => {
    window.open("https://smartstore.naver.com/throo", "_blank");
  };

  const handleDotClick = (index: number) => {
    setIsTransitioning(false);
    setCurrentIndex(index);
  };

  const BannerContent: React.FC<{ bannerType: BannerType["bannerType"] }> = ({
    bannerType,
  }) => {
    const content: {
      [key in BannerType["bannerType"]]: {
        title: string;
        description: string;
        path: string;
      };
    } = {
      ivid: {
        title: "INVISIBLE IDEAS",
        description:
          "일상생활에서 반복되는 사소한 불편함을 새로운 아이디어로 해결하고자 합니다.\n작은 변화를 통해 세상의 큰 변화를 이끄는 서비스를 만들기 위해 항상 노력하겠습니다.",
        path: "",
      },
      throo: {
        title: "FOOD TECH",
        description:
          "스루는 다양한 매장을 워크스루와 드라이브스루로 이용 가능한 앱 서비스 입니다.\n기존 포장주문에 드라이브스루 픽업을 더해 운전자의 관점에서 바라보며 실시간 위치기반 기술과\n도착시간 예측, 운전자의 주행 방향에 최적화된 매장 안내 등 사용자에게 특화된 서비스를 구현합니다.\n\n새로운 드라이브스루의 시작, 스루가 함께하겠습니다.",
        path: "https://throo.co.kr",
      },
      behind: {
        title: "MOBILITY",
        description:
          "비하인드는 매장 사장님들께 스마트한 주문 및 결제 솔루션을 제공하는 솔루션 입니다.\n키오스크, POS, KDS, DID 서비스를 통해 비즈니스 운영 효율성을 극대화하고 있습니다\n\n.언제나 사장님의 뒤에서, 비하인드가 함께하겠습니다.",
        path: "https://behind.ivid.kr",
      },
      knockknock: {
        title: "TRAVEL TECH",
        description:
          "노크노크는 호텔 객실에서 전용 키오스크를 통해 주문부터 결제까지 100% 무인 운영이 가능한 서비스입니다.\n고객은 객실에서 메뉴를 선택하고 결제를 완료하면 편리하게 배달을 받을 수 있습니다.\n노크노크 전용 키오스크는 당사에서 실시간으로 관리하고 있습니다.\n\n새로운 개념의 룸서비스, 노크노크를 경험해보세요.",
        path: "https://knock-knock.ivid.kr",
      },
      "": { title: "", description: "", path: "" },
    };

    const { title, description, path } = content[bannerType] || content[""];

    return (
      <div className="titleWrap">
        <h2>{title}</h2>
        <p>{description}</p>
        {path && (
          <div className="buttonWrap">
            <button onClick={() => window.open(path, "_blank")}>
              {bannerType === "ivid"
                ? " 인비저블아이디어 소개 바로가기"
                : "솔루션 바로가기"}

              <img
                src={`${process.env.PUBLIC_URL}/svg/ic_path.svg`}
                alt="페이지 바로가기 아이콘"
              />
            </button>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 7000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    if (currentIndex === images.length) {
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentIndex(0);
      }, 1000);
    }
  }, [currentIndex, images.length]);
  return (
    <Style>
      <div className="slider">
        <div
          className={`slides ${isTransitioning ? "transition" : ""}`}
          style={{ transform: `translateX(${-currentIndex * 100}%)` }}
        >
          {images &&
            images.length > 0 &&
            images.concat(images[0]).map((image: any, index: number) => (
              <div className="slide" key={index}>
                <img
                  className="bannerImage"
                  src={image.src}
                  alt={`Slide ${index + 1}`}
                />

                <BannerContent bannerType={image.bannerType} />
              </div>
            ))}
        </div>
        <div className="dotWrap">
          {images.map((_: any, index: number) => (
            <span
              key={index}
              className={`dot ${currentIndex === index ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </Style>
  );
};

export default ImageSlider;

const Style = styled.div`
  .slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .slides {
    width: 100%;
    display: flex;
  }
  .slide {
    flex: 1;
    min-width: 100%;
    box-sizing: border-box;
  }
  .slide .bannerImage {
    width: 100%;
    height: 100vh;
  }
  .transition {
    transition: transform 1s ease-in-out;
  }

  .dotWrap {
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 30px;
    bottom: 25%;
    left: 13%;
  }
  .dot {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
  }
  .dot.active:after {
    content: "";
    position: absolute;
    height: 26px;
    width: 26px;
    border: 2px solid #c7c7c7;
    border-radius: 50%;
    bottom: 0px;
    right: 0;
    top: -10px;
    left: -10px;
  }

  @media (max-width: 1400px) {
    .dotWrap {
      width: 100%;
      bottom: 17%;
      left: 0;
    }
  }

  @media (max-width: 800px) {
    .slide .bannerImage {
      height: 85vh;
    }
  }
`;
