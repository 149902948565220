import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

interface PaginateProps {
  pageCount: number;
  forcePage: number;
  handlePageClick: (selected: any) => void;
}

function ReactPaginateWrapper({
  pageCount,
  handlePageClick,
  forcePage,
}: PaginateProps) {
  return (
    <Style>
      <ReactPaginate
        nextLabel={
          <img src={`${process.env.PUBLIC_URL}/svg/ic_next.svg`} alt="next" />
        }
        previousLabel={
          <img src={`${process.env.PUBLIC_URL}/svg/ic_prev.svg`} alt="prev" />
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={0}
        pageCount={pageCount}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="break-page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        disableInitialCallback
        renderOnZeroPageCount={null}
        forcePage={forcePage}
      />
    </Style>
  );
}

export default ReactPaginateWrapper;

const Style = styled.div`
  ul,
  li,
  ol,
  a {
    list-style: none;
    cursor: pointer;
  }
  ul {
    width: 100%;
    margin: 0 auto;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  li {
    margin: 0 10px;

    a {
      padding: 12px;
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      width: 100%;
      color: #979797;
    }
  }
  .active {
    a {
      color: #333333;
    }
  }
  .break-page-item {
    display: none;
  }
  .disabled {
    a {
      color: #555;
    }
  }

  img {
    margin-top: 4px;
    width: 24px;
  }

  @media (max-width: 800px) {
    li {
      margin: 0 8px;
      a {
        padding: 0 10px;
        font-size: 14px;
        line-height: 14px;
      }
    }
    img {
      width: 20px;
    }
  }
`;
