import { atom } from "recoil";

export type ModalType = {
  visibleModal: boolean;
};

export const modalState = atom<ModalType>({
  key: "modalState",
  default: {
    visibleModal: false,
  },
});