import React from "react";
import { useRecoilState } from "recoil";
import { comfirmModalState, ModalType } from "../../recoil/confirm";
import styled from "styled-components";

const ConfirmRecoilModal: React.FC = () => {
  const [modalStates, setModalStates] = useRecoilState<ModalType>(comfirmModalState);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  const closeModal = () => {
    setModalStates((prev) => ({
      ...prev,
      visibleConfirm: false,
      handleConfirm: null,
      title: "",
      successText: "",
      cancelText: "",
      shouldReload: false,
    }));
  };

  const handleConfirm = () => {
    closeModal();

    if (modalStates.handleConfirm) {
      modalStates.handleConfirm();
    }
    if (modalStates.shouldReload) {
      window.location.reload();
      scrollToTop();
    }
  };

  return (
    <Style>
      {modalStates.visibleConfirm && (
        <div className="modalWrap">
          <div className="modal">
            <img
              src={`${process.env.PUBLIC_URL}/svg/ic_success.svg`}
              alt="확인아이콘"
            />
            <h2 className="modalTitle">{modalStates.title}</h2>
            <div className="buttonWrap">
              <button className="successText" onClick={handleConfirm}>
                {modalStates?.successText}
              </button>
              {modalStates?.cancelText !== undefined && (
                <button className="cancelText" onClick={handleConfirm}>
                  {modalStates?.cancelText}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </Style>
  );
};

export default ConfirmRecoilModal;

const Style = styled.div`
  .modalWrap {
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
  }
  .modal {
    display: inline;
    width: auto;
    padding: 27px 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 20px;
    background: #fff;
  }
  h2 {
    margin: 24px 0;
    font-size: 18px;
    font-weight: 400;
    color: #333;
    line-height: 36px;
    white-space: pre-line;
  }
  img {
    width: 100%;
    max-width: 36px;
  }
  .buttonWrap {
    width: 100%;
    display: inline-flex;
  }
  button {
    flex: 1;
    padding: 13px;
    border-radius: 50px;
  }
  .successText {
    background: #6dd9b8;
    color: #fff;
  }
  .cancelText {
  }
  @media (max-width: 700px) {
    .modal {
      width: 85%;
      padding: 24px;
    }
    h2 {
      font-size: 16px;
      line-height: 32px;
    }
  }
`;
