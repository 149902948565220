import React from "react";
import { useRecoilState } from "recoil";
import { modalState, ModalType } from "../../recoil/modal";
import styled from "styled-components";

const ConsultModal: React.FC = () => {
  const [modalStates, setModalStates] = useRecoilState<ModalType>(modalState);

  const closeModal = () => {
    setModalStates({
      visibleModal: false,
    });
  };
  const consultKakaoCustomer = () => {
    window.open("http://pf.kakao.com/_xhbdBG/chat", "_blank");
  };
  const consultKakaoStore = () => {
    window.open("http://pf.kakao.com/_hxbdBG/chat", "_blank");
  };
  const consultKakaoHotel = () => {
    window.open("http://pf.kakao.com/_GKdBG/chat", "_blank");
  };

  return (
    <Style>
      {modalStates.visibleModal && (
        <div className="modalWrap">
          <div onClick={closeModal} className="modalBackground"></div>
          <div className="modal">
            <button onClick={closeModal}>
              <img
                src={`${process.env.PUBLIC_URL}/svg/ic_close.svg`}
                alt="상담 모달 닫기 버튼"
                className="closeButton"
              />
            </button>
            <h2 className="modalTitle">원활한 상담을 위해 선택해주세요</h2>
            <div className="consultType">
              <button onClick={consultKakaoCustomer}>
                <img
                  src="https://web-image-file.s3.ap-northeast-2.amazonaws.com/knock-knock.ivid.kr/consultation01.png"
                  alt="일반고객 상담 바로가기"
                />
              </button>
              <button onClick={consultKakaoStore}>
                <img
                  src="https://web-image-file.s3.ap-northeast-2.amazonaws.com/knock-knock.ivid.kr/consultation02.png"
                  alt="매장 사장님 상담 바로가기"
                />
              </button>
              <button onClick={consultKakaoHotel}>
                <img
                  src="https://web-image-file.s3.ap-northeast-2.amazonaws.com/knock-knock.ivid.kr/consultation03.png"
                  alt="호텔 사장님 상담 바로가기"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </Style>
  );
};

export default ConsultModal;

const Style = styled.div`
  .modalWrap {
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
  }
  .modalBackground {
    width: 100%;
    min-height: 100vh;
    height: 100vh;
  }
  .modal {
    display: inline;
    width: 50%;
    padding: 80px 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 20px;
    background: #fff;
  }
  .modalTitle {
    font-size: 32px;
    font-weight: 600;
    color: #555;
  }
  .consultType {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 57px;
    gap: 30px;

    button img {
      width: 100%;
      max-width: 200px;
    }
  }
  .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
  }

  @media (max-width: 1100px) {
    .modal {
      width: 85%;
      padding: 40px;
    }
    .modalTitle {
      font-size: 19px;
    }
    .consultType {
      margin-top: 40px;
      button img {
        max-width: 100%;
      }
    }
  }
  @media (max-width: 700px) {
    .modal {
      width: 90%;
      padding: 28px 12px;
    }
    .closeButton {
      width: 52px;
      padding: 12px;
    }
    .modalTitle {
      font-size: 17px;
      line-height: 24px;
    }
    .consultType {
      margin-top: 20px;
      gap: 3%;

      button img {
        max-width: 100%;
      }
    }
  }
`;
