import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { RecoilRoot } from "recoil";
import reportWebVitals from "./reportWebVitals";
import CustomToast from "./components/modal/CustomToast";
import ConfirmRecoilModal from "./components/modal/ConfirmRecoilModal";
import ConsultModal from "./components/modal/ConsultModal";


if (
  process.env.REACT_APP_ENV != undefined &&
  process.env.REACT_APP_ENV === "PRD"
) {
  console.log = () => null;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <>
      <RecoilRoot>
      <BrowserRouter
        basename={
          process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : ""
        }
      >
        <App />
        <CustomToast message="" type="success" />
        <ConfirmRecoilModal />
        <ConsultModal />
      </BrowserRouter>
      </RecoilRoot>
    </>
  </React.StrictMode>
);

reportWebVitals();
