import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Parallax } from "react-parallax";
// import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import ImageSlider from "../../components/ImageSlider";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import moment from "moment-timezone";
import ReactPaginateWrapper from "../../components/Pagination";
import { newsData } from "./NewsData";

type newsItemType = {
  src: string;
  url: string;
  title: string;
  company: string;
  published_time: string;
  type: number;
};

// type => 0: 전체 / 1: 스루 / 2: 비하인드 / 3: 노크노크
newsData.sort(
  (a, b) =>
    new Date(b.published_time).getTime() - new Date(a.published_time).getTime()
);

const PAGE_COUNT = 8;

const typeList = ["전체", "스루", "비하인드", "노크노크"];

const NewsPage = () => {
  const [type, setType] = useState<number>(0);

  const [filteredList, setFilteredList] = useState<newsItemType[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPageCount, setTotalPageCount] = useState<number>(1);

  const loadNewWindow = (url: string) => {
    window.open(url, "_blank");
  };

  const pressPageNumber = async (selected: { selected: number }) => {
    setCurrentPage(selected.selected + 1);
  };

  useEffect(() => {
    let newsDataTemp = [];
    if (type > 0) {
      newsDataTemp = [...newsData.filter((item) => item.type === type)];
    } else {
      newsDataTemp = [...newsData];
    }
    setFilteredList(
      newsDataTemp.slice(
        (currentPage - 1) * PAGE_COUNT,
        currentPage * PAGE_COUNT
      )
    );
    let pageCount = Math.ceil(newsDataTemp.length / PAGE_COUNT);
    setTotalPageCount(pageCount);
  }, [type, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RenderTableItem = () => {
    return filteredList.length > 0 ? (
      filteredList.map((item, index) => (
        <div
          className="item"
          key={index}
          onClick={() => loadNewWindow(item.url)}
        >
          <div className="row">
            <h4 className="title">
              {item.title}{" "}
              {moment
                .duration(moment(new Date()).diff(moment(item.published_time)))
                .asDays() < 7 && <div className="new desktop">NEW</div>}
            </h4>
            {moment
              .duration(moment(new Date()).diff(moment(item.published_time)))
              .asDays() < 7 && <div className="new mobile">N</div>}
          </div>

          <h4>
            <span className="ball" />
            {item.company}
          </h4>
          <div className="date">
            <h4>{moment(item.published_time).format("YYYY/MM/DD")}</h4>
          </div>
        </div>
      ))
    ) : (
      <td colSpan={14}>새로운 뉴스가 없어요</td>
    );
  };

  return (
    <Style>
      <div className="wrapper">
        <div className="header">
          <img
            src={`${process.env.PUBLIC_URL}/img/news_header.png`}
            alt="news_header"
          />
          <h1>뉴스</h1>
        </div>
        <div className="content">
          <div className="filter">
            {typeList.map((item, index) => (
              <button
                className={`${type === index && "active"}`}
                onClick={() => setType(index)}
              >
                <h3>{item}</h3>
              </button>
            ))}
          </div>
          <div className="tableWrap">
            <div className="table">
              <div className="head">
                <h3 className="title">제목</h3>
                <h3>언론사</h3>
                <h3>등록일</h3>
              </div>
              <div className="body">
                <RenderTableItem />
              </div>
            </div>
          </div>
          <div className="pageWrap">
            <ReactPaginateWrapper
              pageCount={totalPageCount}
              forcePage={currentPage - 1}
              handlePageClick={pressPageNumber}
            />
          </div>
        </div>
      </div>
    </Style>
  );
};

export default NewsPage;

const Style = styled.div`
  .desktop {
    display: inline;
  }
  .mobile {
    display: none;
  }

  /* 공통 */
  .header {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      filter: brightness(0.5);
    }
    h1 {
      position: absolute;
      left: 16%;
      top: 50%;
      transform: translateY(-50%);
      font-size: 80px;
      line-height: 110px;
      color: #fff;
      font-weight: 700;
    }
  }
  .content {
    padding: 3% 16%;
  }
  .filter {
    display: flex;
    align-items: center;
    padding: 6% 0;
    gap: 10px;
    button {
      padding: 6px 32px;
      border: 1px solid #c7c7c7;
      border-radius: 50px;
      background: #fff;
      h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #555;
      }
    }
    button.active {
      border-color: #111111;
      background: #111111;
      h3 {
        color: #fff;
      }
    }
  }

  .table {
    width: 100%;
    .head {
      display: flex;
      align-items: center;
      background: #555;
      h3 {
        width: 20%;
        line-height: 28px;
        font-weight: 500;
        color: #fff;
        padding: 10px 0;
        text-align: center;
      }
      h3.title {
        width: 60%;
      }
    }

    .body {
      .row {
        width: 60%;
        display: flex;
        align-items: center;
      }
      .item {
        border-bottom: 1px solid #ececec;
        display: flex;
        align-items: center;
        padding: 40px 0;
        cursor: pointer;
        h4 {
          width: 20%;
          text-align: center;
          line-height: 24px;
          font-weight: 500;

          span.ball {
            margin-left: 0;
            margin-right: 8px;
            padding: 3px 12.5px;
            background: #d9d9d9;
            border-radius: 50px;
          }
        }

        .new {
          margin-left: 6px;
          padding: 5px 14px;
          border-radius: 50px;
          background: #ff3131;
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
          color: #fff;
        }

        h4.title {
          width: 100%;
          text-align: left;
        }
      }
      .date {
        width: 20%;
        h4 {
          width: 100%;
        }
      }
    }
  }

  .pageWrap {
    padding: 30px 0 60px 0;
  }

  @media (max-width: 800px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline;
    }
    .header {
      display: flex;
      align-items: flex-start;
      img {
        height: 240px;
        object-fit: cover;
        filter: brightness(0.5);
      }
      h1 {
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        line-height: 48px;
        text-align: center;
      }
    }
    .content {
      padding: 3% 0;
    }
    .filter {
      padding: 5%;
      gap: 6px;
      button {
        padding: 8px 14px;
        h3 {
          white-space: nowrap;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .table {
      padding: 20px;
      .head {
        display: none;
      }
      .body {
        border-top: 1px solid #ececec;
        .row {
          width: 100%;
        }
        .item {
          flex-direction: column;
          align-items: flex-start;
          gap: 14px;
          padding: 16px 0;
          h4 {
            width: auto;
            font-size: 12px;
            line-height: 18px;
            padding: 0;
            span.ball {
              margin-left: 0;
              margin-right: 4px;
              padding: 1px 8px;
            }
          }
          .new {
            margin-left: 4px;
            padding: 2px 4px;
            font-size: 10px;
            line-height: 10px;
          }

          h4.title {
            width: fit-content;
            max-width: 90%;
            font-size: 16px;
            color: #000;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .date {
            display: none;
          }
        }
      }
    }
    .pageWrap {
      padding: 16px 0 40px 0;
    }
  }
`;
