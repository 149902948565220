import React from 'react';
import { useRecoilValue } from 'recoil';
import { toastState } from '../../recoil/toast';
import styled from 'styled-components';

interface CustomToastProps {
  message: string;
  type: string;
}

const CustomToast: React.FC<CustomToastProps> = () => {
  const toast = useRecoilValue(toastState);

  if (!toast.isVisible) {
    return null;
  }

  return (
    <Style>
      <div className="wrap">
        <div className="customToastWrap">
          <div className="customToast">
            {toast.type === 'error' ? (
              <img src={`${process.env.PUBLIC_URL}/svg/ic_error.svg`} alt="에러아이콘" />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/svg/ic_success.svg`} alt="확인아이콘"  />
            )}
            <p className="toastMessage">{toast.message}</p>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default CustomToast;

const Style = styled.div`
  .wrap {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    z-index: 9999999;
  }
  .customToastWrap {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .customToast {
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 30px;
    text-align: center;
    border-radius: 50px;
    background: #ffffff;
  }
  .toastMessage {
    font-size: 20px;
    margin: 0;
    margin-left: 18px;
    font-weight: 500;
    color: #555555;
  }
  img {
    width: 100%;
    max-width: 36px;
  }

  @media (max-width: 600px) {
    .customToast {
      max-width: 85%;
    }
    .toastMessage {
      margin-left: 6px;
      line-height: 24px;
      word-break: keep-all;
    }
  }
`;
