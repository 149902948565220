export const newsData = [
    {
      src: "https://www.venturesquare.net/wp-content/uploads/2023/09/02.-이미지-스루-LG유플러스-차량용-앱-서비스-공모전-우수상-수상_230907-1.jpg",
      url: "https://www.venturesquare.net/895266",
      title: "스루, LG유플러스 차량용 앱 서비스 공모전 우수상 수상",
      company: "벤처스퀘어",
      published_time: "2023-09-07",
      type: 0,
    },
    {
      src: "http://m.segyebiz.com/content/image/2023/08/16/20230816512980.jpg",
      url: "http://m.segyefn.com/newsView/20230816512981",
      title: "인비저블아이디어, 한국결제네트웍스와 키오스크 업무협약 체결",
      company: "세계비즈",
      published_time: "2023-08-16",
      type: 0,
    },
    {
      src: "https://cdn.startupn.kr/news/photo/202404/45247_46107_2956.png",
      url: "https://www.startupn.kr/news/articleView.html?idxno=45247",
      title:
        '"전통시장도 드라이브 스루 가능해요”... 중랑동부시장, ‘스루’서비스 도입',
      company: "스타트업엔",
      published_time: "2024-04-08",
      type: 1,
    },
    {
      src: "https://cdn.startuptoday.kr/news/photo/202411/49550_37987_5042.jpg",
      url: "https://www.startuptoday.kr/news/articleView.html?idxno=49550",
      title:
        "인비저블아이디어, ‘서울인 호텔 바이 더디자너스’에 노크노크 라운지 솔루션 출시",
      company: "스타트업투데이",
      published_time: "2024-11-07",
      type: 3,
    },
    {
      src: "https://cdn.startupn.kr/news/photo/202411/49394_50632_1635.jpg",
      url: "https://www.startupn.kr/news/articleView.html?idxno=49394",
      title:
        "노크노크, 스마트 룸서비스 솔루션 거점 확대 나선다",
      company: "스타트업뉴스",
      published_time: "2024-11-27",
      type: 3,
    },
    {
      src: "https://image.edaily.co.kr/images/photo/files/NP/S/2024/06/PS24061200163.jpg",
      url: "https://m.edaily.co.kr/News/Read?newsId=01479286638921064&mediaCodeNo=257",
      title: "이제 눈치보지 말고 호텔에서 배달음식 시키세요",
      company: "이데일리",
      published_time: "2024-06-12",
      type: 3,
    },
  
    {
      src: "https://www.koit.co.kr/news/photo/202306/114059_66671_318.jpg",
      url: "https://www.koit.co.kr/news/articleView.html?idxno=114059",
      title: "인비저블아이디어, SW 중심 키오스크 신사업으로 스루 매장 확대 모색",
      company: "정보통신신문",
      published_time: "2023-06-19",
      type: 2,
    },
    {
      src: "https://www.venturesquare.net/wp-content/uploads/2023/06/unnamed-3.jpg",
      url: "https://www.venturesquare.net/883251",
      title: "스루, KB라이프파트너스와 업무협약 체결",
      company: "벤처스퀘어",
      published_time: "2023-06-02",
      type: 1,
    },
    {
      src: "https://img.etnews.com/news/article/2024/08/08/news-p.v1.20240808.858d43d1636f4b348d27e8bf1b288d50_P1.jpg",
      url: "https://www.etnews.com/20240808000204",
      title: "[카&테크]오비고 커넥티비티 서비스 '워치마일·스루'",
      company: "전자신문",
      published_time: "2024-08-08",
      type: 1,
    },
    {
      src: "https://cdn.discoverynews.kr/news/photo/202407/1047866_1073424_1832.png",
      url: "https://www.discoverynews.kr/news/articleView.html?idxno=1047866",
      title:
        "전주 한옥마을, 스마트 룸서비스 “노크노크” 도입으로 혁신적인 변화 시작",
      company: "조선경제",
      published_time: "2024-07-31",
      type: 3,
    },
  ];