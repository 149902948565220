import { atom } from "recoil";

export type ModalType = {
  visibleConfirm: boolean;
  handleConfirm: (() => void) | null;
  title: string;
  successText?: string;
  cancelText?: string;
  shouldReload?: boolean;
};

export const comfirmModalState = atom<ModalType>({
  key: "comfirmModalState",
  default: {
    visibleConfirm: false,
    shouldReload: false,
    handleConfirm: null,
    title: "",
    successText: "",
    cancelText: "",
  },
});
