export type HistoryListType = {
  date: string;
  date_color: string;
  historys: {
    month: number;
    title: string;
    bold?: boolean;
  }[];
  mobile_historys: {
    month: number;
    title: string;
    bold?: boolean;
  }[];
};

export const historyList: HistoryListType[] = [
  {
    date: "2020",
    date_color: "#C7C7C7",
    historys: [
      {
        month: 2,
        title: "인비저블아이디어 설립",
      },
    ],
    mobile_historys: [
      {
        month: 2,
        title: "설립",
      },
    ],
  },
  {
    date: "2021",
    date_color: "#C7C7C7",

    historys: [
      {
        month: 1,
        title: "드라이브스루 플랫폼, 스루  베타서비스 출시",
      },
      {
        month: 2,
        title: "현대자동차 PoC 진행",
      },
      {
        month: 7,
        title: "삼성 C.LAB 대구 12기 수료",
      },
      {
        month: 9,
        title: "중기부 R&D 지원사업 선정",
      },
      {
        month: 11,
        title: "KB 국민카드 오픈스테이지 선정",
      },
    ],
    mobile_historys: [
      {
        month: 7,
        title: "‘스루‘서비스 런칭, 현대차 그룹 PoC 중",
      },
      {
        month: 7,
        title: "삼성 C-LAB 12기",
      },
    ],
  },
  {
    date: "2022",
    date_color: "#A6A6A6",

    historys: [
      {
        month: 2,
        title: "D.CAMP D.DAY 준우승",
      },
      {
        month: 4,
        title: "IBK 창공 구로 8기 수료",
      },
      {
        month: 5,
        title: "신한 스퀘어브릿지 대구 1기 수료",
      },
      {
        month: 6,
        title: "기보 벤처캠프 우수참여기업 선정",
      },
      {
        month: 6,
        title: "SEED 투자유치  - 인라이트벤처, 신용보증기금",
        bold: true,
      },
    ],
    mobile_historys: [
      {
        month: 2,
        title: "D-CAMP 2월 준우승",
      },
      {
        month: 4,
        title: "50개 브랜드 MOU체결, 1500개점 입점",
      },
      {
        month: 5,
        title: "신용보증기금, 인라이트벤처스 투자유치",
      },
      {
        month: 6,
        title: "기보벤처캠프 10기 우수졸업기업",
      },
      {
        month: 7,
        title: "신한스퀘어브릿지 1기",
      },
      {
        month: 8,
        title: "IBK창공 구로8기",
      },
    ],
  },
  {
    date: "2023",
    date_color: "#7D7D7D",

    historys: [
      {
        month: 2,
        title: "창업도약패키지 (경북대, KT 협업과제)",
      },
      {
        month: 3,
        title: "TIPS 선정",
      },
      {
        month: 4,
        title: "외식업 토탈솔루션 ‘BEHIND’ 출시",
        bold: true,
      },
      {
        month: 5,
        title: "소진공 스마트상점 기술보급기업 선정",
      },
      {
        month: 9,
        title: "LG U+, 현대모비스 차량용 앱개발 공모전 우수상 수상",
      },
      {
        month: 11,
        title: "KNOCK KNOCK 기본설계 완료",
      },
      {
        month: 12,
        title: "시장 검증 - 수요자 검증 (출시 전 3 호텔 수주)",
      },
    ],
    mobile_historys: [
      {
        month: 1,
        title: "‘BEHIND’ 출시",
      },
      {
        month: 2,
        title: "소진공 스마트상점 기술보급 기업 선정",
      },
      {
        month: 3,
        title: "TIPS 선정",
      },
      {
        month: 4,
        title: "KT협업 사업선정 서빙로봇 PoC",
      },
      {
        month: 9,
        title: "LG U+,현대모비스 차량용앱 PoC",
      },
    ],
  },
  {
    date: "2024",
    date_color: "#4F4F4F",

    historys: [
      {
        month: 2,
        title: "KNOCK KNOCK MVP 출시 베타테스트 완료",
      },
      {
        month: 5,
        title: "LG U+ 음성인식 기반 차량용 앱 스루_개발 PoC 완료",
      },
      {
        month: 5,
        title: "‘KNOCK KNOCK’ 정식 출시, 71 객실 2성 호텔 납품",
        bold: true,
      },
      {
        month: 7,
        title: "전주시 실증사업  선정, 전주한옥마을 8개 업체 30객실 납품",
      },
      {
        month: 8,
        title: "잠실 송파, 역삼 강남, 전주, 김제, 시흥, 부평 클러스터 구축",
      },
    ],
    mobile_historys: [
      {
        month: 5,
        title: "‘KNOCK KNOCK’ 출시",
      },
      {
        month: 5,
        title: "오비고 차량용앱 PoC",
      },
    ],
  },
];
