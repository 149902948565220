import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import styled from "styled-components";
import { useToast } from "../controller/ToastController";
import MainController, { ContactusType } from "../controller/mainController";
import { comfirmModalState, ModalType } from "../recoil/confirm";
import { useRecoilState } from "recoil";

const ContactComponent = () => {
  const showToast = useToast();
  const [modalOpen, setModalOpen] = useRecoilState<any>(comfirmModalState);
  const inquiryType = [
    { value: "0", label: "제품문의" },
    { value: "1", label: "사업관련" },
    { value: "2", label: "서비스관련" },
  ];
  const businessType = [
    { value: "0", label: "노크노크" },
    { value: "1", label: "비하인드" },
    { value: "2", label: "스루" },
  ];
  const [inquiry, setInquiry] = useState<string>("");
  const [business, setBusiness] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phonenumber, setPhonenumber] = useState<string>("");
  const [text, setText] = useState<string>("");

  const handleInquiryChange = (selectedOption: any) => {
    setInquiry(selectedOption?.label);
  };
  const handleBusinessChange = (selectedOption: any) => {
    setBusiness(selectedOption?.label);
  };
  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };
  const handleCompanyChange = (e: any) => {
    setCompany(e.target.value);
  };
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e: any) => {
    setPhonenumber(e.target.value);
  };
  const handleTextChange = (e: any) => {
    setText(e.target.value);
  };
  const resetAll = () => {
    setInquiry("");
    setBusiness("");
    setName("");
    setCompany("");
    setEmail("");
    setPhonenumber("");
    setText("");
  };

  const contactus = async () => {
    if (inquiry === "") {
      showToast("문의 구분을 선택해주세요.", "error");
    } else if (business === "") {
      showToast("관심 사업을 선택해주세요.", "error");
    } else if (name === "") {
      showToast("이름을 입력해주세요.", "error");
    } else if (company === "") {
      showToast("회사명을 입력해주세요.", "error");
    } else if (email === "") {
      showToast("이메일을 입력해주세요.", "error");
    } else if (phonenumber === "") {
      showToast("핸드폰번호를 입력해주세요.", "error");
    } else {
      try {
        const data: ContactusType = {
          inquiry_type: inquiry,
          business_type: business,
          name: name,
          company: company,
          email: email,
          phonenumber: phonenumber,
          text: text,
        };
        const oRes = await MainController.contactus(data);

        console.log("oRes", oRes);
        if (oRes && oRes.data) {
          const oResponse = oRes.data;
          if (oResponse.ok) {
            setModalOpen({
              visibleConfirm: true,
              title:
                "상담 신청이 완료되었습니다.\n통화 가능 시간에 상담 도와드리겠습니다.",
              successText: "확인",
              shouldReload: true,
            });
            resetAll();
          }
        }
      } catch (err) {
        console.log("err---->", err);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    resetAll();
  }, []);

  return (
    <Style>
      <div className="contactWrap">
        <div className="left">
          <h2>CONTACT US</h2>
          <img
            src={`${process.env.PUBLIC_URL}/img/map.png`}
            alt="회사 지도"
            className="mapImage"
          />
          <div className="contactInfo flex">
            <img
              src={`${process.env.PUBLIC_URL}/svg/ic_phone.svg`}
              alt="전화기 아이콘"
            />
            <p>1670-5324</p>
          </div>
          <div className="contactInfo flex">
            <img
              src={`${process.env.PUBLIC_URL}/svg/ic_place.svg`}
              alt="지도 아이콘"
            />
            <div className="addressWrap">
              <p>대구 북구 호암로 51, 메이커스페이스동 2층 (본사)</p>
              <p>서울 강남구 선릉로 622, 대영빌딩 2층 (연구소)</p>
            </div>
          </div>
        </div>
        <div className="right">
          <Select
            options={inquiryType}
            placeholder="문의 구분을 선택해주세요"
            onChange={handleInquiryChange}
          />
          <Select
            options={businessType}
            placeholder="관심 사업을 선택해주세요"
            onChange={handleBusinessChange}
          />
          <input
            onChange={(e) => handleNameChange(e)}
            type="text"
            placeholder="이름"
          />
          <input
            onChange={(e) => handleCompanyChange(e)}
            type="text"
            placeholder="회사명"
          />
          <input
            onChange={(e) => handleEmailChange(e)}
            type="text"
            placeholder="이메일"
          />
          <input
            onChange={(e) => handlePhoneChange(e)}
            type="text"
            placeholder="연락처"
          />
          <textarea
            onChange={(e) => handleTextChange(e)}
            placeholder="문의내용을 입력해주세요"
            name="contactQuestion"
            id="contactQuestion"
          ></textarea>
          <button onClick={contactus} className="contactSubmitButton">
            문의 신청하기
          </button>
        </div>
      </div>
    </Style>
  );
};
export default ContactComponent;

const Style = styled.div`
  p {
    color: #c7c7c7;
  }

  /* contactWrap */
  .contactWrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 5vw;
    padding: 100px 300px;
    background: #1d1d1d;
  }
  .mapImage {
    max-width: 440px;
  }

  /* left */
  .left {
    flex: 1;
    h2 {
      margin-top: 60px;
      color: #fff;
      font-size: 70px;
      font-weight: 700;
      white-space: nowrap;
    }
  }
  .mapImage {
    width: 100%;
    padding-top: 69px;
    padding-bottom: 87px;
  }

  /* right */
  .right {
    flex: 1;

    input,
    textarea {
      width: 100%;
      margin-bottom: 30px;
      padding: 15px 24px;
      border: 1px solid #555;
      background: none;
      outline: none;
      font-size: 18px;
      font-weight: 400;
      color: #c7c7c7;
    }
    input {
      border: none;
      border-bottom: 1px solid #555;
    }
    textarea {
      height: 192px;
      border-radius: 10px;
    }
  }
  .contactInfo {
    margin-bottom: 10px;
    align-items: center;
    gap: 16px;
  }
  .contactInfo {
    align-items: flex-start;
  }
  .addressWrap p {
    margin-top: 2px;
    margin-bottom: 8px;
  }

  .contactSubmitButton {
    width: 100%;
    padding: 13px;
    background: #fff;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: #555;
    border-radius: 10px;
  }

  /* select */
  .css-b62m3t-container {
    margin-bottom: 30px;
  }
  .css-13cymwt-control {
    background: #1d1d1d;
    border-color: #555;
    padding: 2px 12px;
  }
  .css-1dimb5e-singleValue {
    color: #c7c7c7;
  }
  @media (max-width: 1400px) {
    .left {
      h2 {
        font-size: 60px;
      }
    }
    .contactWrap {
      padding: 91px 8vw;
    }
  }
  @media (max-width: 800px) {
    .left {
      h2 {
        margin-top: 20px;
        margin-bottom: 100px;
        font-size: 40px;
      }
    }
    h2 {
      text-align: center;
      font-size: 32px;
      margin-bottom: 30px;
    }
    .contactWrap {
      width: 100%;
      padding: 78px 4vw;
      flex-direction: column;
    }
    .left {
      width: 100%;
      .mapImage {
        max-width: 400px;
        display: flex;
        margin: 0 auto;
        padding: 20px 0;
      }
    }

    .right {
      input {
        margin-bottom: 15px;
      }
    }

    .contactInfo {
      margin-top: 10px;
    }
    .contactInfo p {
      margin-bottom: 5px;
      font-size: 15px;
    }
  }
`;
